import './button.css'

function Button({
  label,
  onClick,
  music
}: {
  label: string
  onClick: Function
  music: boolean
}) {
  const handleTouchEnd = (e: any) => {
    e.preventDefault()
  }
  return (
    <div
      className={music ? 'music' : 'genericButton'}
      onClick={onClick}
      onTouchStart={onClick}
      onTouchEnd={handleTouchEnd}
    >
      {label}
    </div>
  )
}

export default Button
