import { useEffect, useRef, useState } from 'react'
import background from '../../assets/backgrounds/homepage.mp4'
import profile from '../../assets/pp.jpg'
import { FiInstagram } from 'react-icons/fi'
import { VscGithub } from 'react-icons/vsc'
import { TbBrandLinkedin } from 'react-icons/tb'
import Background from '../../components/background/background'
import Button from '../../components/button/button'
import Modal from '../../components/modal/modal'
import './homepage.css'
function Homepage({ setPage }: { setPage: Function }) {
  const [loaded, setLoaded] = useState(false)


  useEffect(() => {
    
    setLoaded(true)
  }, [loaded])

  return (
    <div className={'page ' + (loaded ? "visible":"hidden")}>
      <Background background={background} />
      <div className="content">
        <div className="nameTitle">Thanushen Balaskandar</div>
        <div className="jobTitle">Front-end Engineer</div>
        <div className="icons">
          <a href="https://www.instagram.com/lord.balaskandar/">
            <FiInstagram className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/thanushenbalaskandar/">
            <TbBrandLinkedin className="icon" />
          </a>
          <a href="https://github.com/lord-balaskandar">
            <VscGithub className="icon" />
          </a>
        </div>
        <div className="introText">Passionate software developer specialising in frontend or full-stack engineering roles, with experience in predominately in Node JS, React, Javascript and Typescript.</div>
        <div className="ppFrame">
          <img className="profilePicture" src={profile} alt="Image"></img>
        </div>
        <div className="homeMenu">
          <Modal>
            <Button
              label="Projects"
              onClick={() => {
                setPage('projects')
              }}
              music={false}
            />
            <Button
              label="Experience"
              onClick={() => {
                setPage('experience')
              }}
              music={false}
            />
            <Button
              label="Qualifications"
              onClick={() => {
                setPage('qualifications')
              }}
              music={false}
            />
            <Button
              label="Skills"
              onClick={() => {
                setPage('skills')
              }}
              music={false}
            />
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Homepage
