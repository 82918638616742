import CVPage from '../../components/cvPage/cvPage'
import background from '../../assets/backgrounds/experience.mp4'
import main from '../../assets/cv/main.tex'
import './experience.css'
import { useEffect, useRef, useState } from 'react'
import CVDataManager from '../../common/CVDataManager'

function Experience({ setPage }: { setPage: Function }) {
  const [data, setData] = useState([])
  const [loaded, setLoaded] = useState(false)


  useEffect(() => {
    fetch(main)
      .then((r) => r.text())
      .then((data) => setData(CVDataManager.parseExperienceData(data)))

    if (data.length > 0) setLoaded(true)
  }, [data, loaded])

  return (
    <div>
      <CVPage background={background} setPage={setPage}>
        {data.map((item, i) => {
          return (
            <div key={'e' + i} className="section">
              <div className="jobTitle">{item.jobTitle}</div>
              <div className="jobContent">
                {item.location + ' - ' + item.company}
              </div>
              <div className="jobContent">{item.date}</div>
              <ul className="skills">
                {item.skills.map((skill, index) => {
                  return <li key={i + 'es' + index}>{skill}</li>
                })}
              </ul>
            </div>
          )
        })}
      </CVPage>
    </div>
  )
}

export default Experience
