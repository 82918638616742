import Border from '../border/border'
import './modal.css'

function Modal({ children }: { children: any }) {
  return (
    <div className="modal">
      <Border>{children}</Border>
    </div>
  )
}

export default Modal
