import main from '../assets/cv/main.tex'

class CVDataManager {
  static parseExperienceData(data: string) {
    return data.match(/cvevent[\s\S]*?newline/g)?.map((item) => {
      let info: any | null = item
        .split('\n')[0]
        .match(/{[\s\S]*?}/g)
        ?.map((item) => item.replace('{', '').replace('}', ''))
      return {
        jobTitle: info[0],
        company: info[1],
        date: info[2],
        location: info[3],
        skills: item
          .match(/small[\s\S]*?}/g)
          ?.map((item) => item.replace('small{', '').replace('}', '')),
        stack: item
          .match(/Tech Stack: [\s\S]*?}/g)
          ?.map((item) => item.replace('Tech Stack: ', '').replace('}', ''))
      }
    })
  }

  static parseTechSkillsData(data: string) {
    return data
      .match(/cvsection{Technical Skills}[\s\S]*?medskip/)[0]
      .split('cvtag')
      .slice(1)
      .filter((item) => item.indexOf('medskip') === -1)
      .map((item) =>
        item.replace('\n\\', '').replace(/[{}]/g, '').replace('\\newline', '')
      )
  }

  static parseKeySkillsData(data: string) {
    return data
      .match(/cvsection{Key Skills}[\s\S]*?end/)[0]
      .match(/begin{itemize}[\s\S]*?end/)[0]
      .split('item')
      .slice(2)
      .map((item) =>
        item
          .replace('\n', '')
          .replace('\\', '')
          .replace('    ', '')
          .slice(1)
          .replace(/[{}]/g, '')
          .replace('.end', '')
      )
  }
}

export default CVDataManager
