import CVPage from '../../components/cvPage/cvPage'
import background from '../../assets/backgrounds/skills.mp4'
import page1 from '../../assets/cv/page1sidebar.tex'
import './skills.css'
import { useEffect, useRef, useState } from 'react'
import CVDataManager from '../../common/CVDataManager'

function Skills({ setPage }: { setPage: Function }) {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [loaded, setLoaded] = useState(false)


  useEffect(() => {
    fetch(page1)
      .then((r) => r.text())
      .then((text) => {
        setData(
            CVDataManager.parseTechSkillsData(text)
            
        )
        setData2(
            CVDataManager.parseKeySkillsData(text)
            
        )
      })

    if(data.length > 0) setLoaded(true)
  }, [data, loaded])

  return (
    <div >
      <CVPage background={background} setPage={setPage} >
        <div className='section'>
            <div className='title'>Technical Skills</div>
            <ul className='skills tech'>
                {data.map((item, index) => {
                    return (<li key= {"t" + index}>{item}</li>)
                })}
            </ul>
        </div>
        <div className='section'>
            <div className='title'>Key Skills</div>
            <ul className='skills'>
                {data2.map((item, index) => {
                    return (<li key= {"ks" + index}>{item}</li>)
                })}
            </ul>
        </div>
      </CVPage>
    </div>
  )
}

export default Skills
