import { useEffect, useState } from 'react'
import './background.css'

function Background({
  background,
  videoRef
}: {
  background: any
  videoRef: any
}) {
  const [loaded, setLoaded] = useState(false)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    setInterval(() => {
      setSeconds(seconds + 1)
    }, 1000)
  })

  return (
    <div>
      <div
        className={'loading ' + (!loaded && seconds >= 2 ?  'visible' : 'hidden' )}
      >
        <div>
          LOADING...
        </div>
      </div>
      <>
        <video
          autoPlay
          loop
          muted
          playsInline
          src={background}
          preload="auto"
          onLoadedData={() => setLoaded(true)}
        />
      </>
    </div>
  )
}

export default Background
