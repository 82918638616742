import { useState, useEffect, useRef } from 'react'
import page1 from '../../assets/cv/page1sidebar.tex'
import background from '../../assets/backgrounds/qualifications.mp4'
import CVPage from '../../components/cvPage/cvPage'
import './qualifications.css'

function Qualifications({ setPage }: { setPage: Function }) {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])


  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    fetch(page1)
      .then((r) => r.text())
      .then((data) => {
        setData(
          data
            .match(/cvsection{Qualifications}[\s\S]*?cvsection/g)[0]
            .match(/cvevent[\s\S]*?cvsection/g)[0]
            .split('cvevent')
            .filter((item) => item && item !== '')
            .map((item) =>
              item
                .replace('\n', '')
                .replace('\n', '')
                .replace(String.fromCharCode(92), '')
                .replace('cvsection', '')
                .match(/{([^}]+)}/g)
            )
            .map((item) => {
              return {
                qualification: item[0].replace(/[{}]/g, ''),
                issuer: item[1].replace(/[{}]/g, ''),
                date: item[2].replace(/[{}]/g, ''),
                company: item[3].replace(/[{}]/g, '')
              }
            })
        )
        setData2(
          data
            .match(/cvsection{Education}[\s\S]*?cvsection/g)[0]
            .match(/cvevent[\s\S]*?cvsection/)[0]
            .match(/cvevent[\s\S]*?smallskip/)
            .map((item) => {
              let info = item
                .match(/cvevent[\s\S]*?\n/)[0]
                .replace('cvevent', '')
                .match(/{([^}]+)}/g)
                .map((item) => item.replace(/[{}]/g, ''))
              return {
                qualification: info[0],
                issuer: info[1],
                date: info[2],
                location: info[3],
                skills: item
                  .match(/.*newline/g)
                  .map((item) =>
                    item.replace(String.fromCharCode(92) + 'newline', '')
                  )
              }
            })
        )
      })

    if(data.length > 0 && data2.length > 0) setLoaded(true)
  }, [data, loaded])

  return (
    <div >
      <CVPage background={background} setPage={setPage} >
        <div className="section">
          <div className="title">Qualifications</div>
          {data.map((item, i) => {
            return (
              <div key = {i+"q"}className="section">
                <div className="jobTitle">{item.qualification}</div>
                <div className="jobContent">
                  {item.issuer + ' - ' + item.company}
                </div>
                <div className="jobContent">{item.date}</div>
              </div>
            )
          })}
        </div>
        <div className="section">
          <div className="title">Education</div>

          {data2.map((item, i) => {
            return (
              <div key = {i+"e"} className="section">
                <div className="jobTitle">{item.qualification}</div>
                <div className="jobContent">{item.issuer}</div>
                <div className="jobContent">{item.location}</div>
                <div className="jobContent">{item.date}</div>
                <ul className="skills">
                  {item.skills.map((skill, index) => {
                    return <li key={i+'es' + index}>{skill}</li>
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </CVPage>
    </div>
  )
}

export default Qualifications
