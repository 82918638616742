import './musicPlayer.css'
import { useState, useRef, useEffect } from 'react'
import Button from '../button/button'

function MusicPlayer({
  isPlaying,
  setPlaying,
  currentSong,
  setEnded
}: {
  isPlaying: boolean
  setPlaying: Function
  currentSong: any
  setEnded: Function
}) {
  const audioRef = useRef()

  const start = () => {
    isPlaying ? audioRef.current.pause() : audioRef.current.play()
    setPlaying(!isPlaying)
  }

  const handleEnded = () => {
    audioRef.current.pause()
    setEnded()
    audioRef.current.load()
    setTimeout(() => {
      audioRef.current.play()
    }, 200)
  }

  return (
    <div className="musicPlayerContainer">
      <audio
        ref={audioRef}
        src={'/music/' + currentSong}
        style={{ display: 'none' }}
        onEnded={handleEnded}
      />
      <Button label={isPlaying ? '⏸︎' : '⏵'} music={true} onClick={start} />
      <Button label="⏭︎" music={true} onClick={handleEnded} />
      <div className="mediaText ">{currentSong.replace('.mp3', '')}</div>
    </div>
  )
}

export default MusicPlayer
