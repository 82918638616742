import './cvPage.css'
import Background from '../background/background'
import Modal from '../modal/modal'
import Button from '../button/button'
import { useEffect, useState } from 'react'

function CVPage({
  background,
  setPage,
  videoRef,
  children,
}: {
  background: any
  setPage: Function
  videoRef: any
  children: any
}) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [loaded])

  return (
    <div className={'page ' + (loaded ? "visible":"hidden")} >
      <Background background={background} videoRef={videoRef} />

      <div className="content">
        <div className="backButton">
          <Button label="Back" onClick={() => setPage('home')} />
        </div>
        <Modal className="cvPageModal">
          <div className="pageContent">{children}</div>
        </Modal>
      </div>
    </div>
  )
}

export default CVPage
