import { argv0 } from 'process'
import React, { useState, Component } from 'react'
import './App.css'
import MusicPlayer from './components/musicPlayer/musicPlayer'
import Experience from './pages/experience/experience'
import Homepage from './pages/homepage/homepage'
import Qualifications from './pages/qualifications/qualifications'
import Skills from './pages/skills/skills'
import Projects from './pages/projects/projects'

const songs = [
  '451 - tina.mp3',
  '451 - figured out.mp3',
  'tory lanez - hurts me (slowed & reverbed).mp3',
  'Tory Lanez - Why Did I.mp3',
  '88GLAM - Kawasaki.mp3',
  'tory lanez - ballad of a badman.mp3',
  'the weeknd - a lesser man (slowed & reverb).mp3',
  'don toliver - mi$$ion.mp3'
]

class App extends Component<
  {},
  {
    page: string
    key: number
    music: { isPlaying: boolean; currentSong: any }
  }
> {
  constructor(props: {
    page: string
    key: number
    music: { isPlaying: boolean; currentSong: any }
  }) {
    super(props)
    this.state = {
      page: 'home',
      key: 0,
      music: {
        isPlaying: false,
        currentSong: songs[0]
      }
    }
  }

  setPlaying = (playing: boolean) => {
    this.setState({
      music: { isPlaying: playing, currentSong: this.state.music.currentSong }
    })
  }

  setEnded = () => {
    let index = songs.indexOf(this.state.music.currentSong)
    if (index + 1 == songs.length)
      this.setState({ music: { isPlaying: true, currentSong: songs[0] } })
    else
      this.setState({
        music: { isPlaying: true, currentSong: songs[index + 1] }
      })
  }

  componentDidMount(): void {
    window.addEventListener('resize', () => {
      this.setState({ key: this.state.key + 1 })
    })
  }

  setPage = (page: string) => {
    this.setState({ page: page })
  }
  pageMap: any = {
    home: <Homepage setPage={this.setPage} />,
    experience: <Experience setPage={this.setPage} />,
    qualifications: <Qualifications setPage={this.setPage} />,
    skills: <Skills setPage={this.setPage} />,
    projects: <Projects setPage={this.setPage}/>
  }
  render() {
    return (
      <div className="App" key={this.state.key}>
        <MusicPlayer
          isPlaying={this.state.music.isPlaying}
          setPlaying={this.setPlaying}
          currentSong={this.state.music.currentSong}
          setEnded={this.setEnded}
        />
        {this.pageMap[this.state.page]}
      </div>
    )
  }
}

export default App
