import { useEffect, useRef, useState } from 'react'
import background from '../../assets/backgrounds/projects.mp4'
import { Octokit } from 'octokit'
import CVPage from '../../components/cvPage/cvPage'
import './projects.css'

function Projects({ setPage }: { setPage: Function }) {
  const [data, setData] = useState([])

  const [loaded, setLoaded] = useState(false)

  const octokit = new Octokit({
    auth: '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'
  })
  const handleGitData = async () => {
    const repos = await octokit.request('GET /users/lord-balaskandar/repos')
    setData(
      repos.data
        .filter(
          (item) => item.description && item.description.indexOf('!') !== -1
        )
        .map((item) => {
          return {
            name: item.name,
            description: item.description,
            url: item.html_url,
            language: item.language,
            lastUpdated: new Date(item.pushed_at).getTime(),
            deploymentURL: item.has_pages  && item.name !== "latex-cv-website" ? "https://lord-balaskandar.github.io/" + item.name : undefined
          }
        })
        .sort((a, b) => b.lastUpdated - a.lastUpdated)
    )
  }

  useEffect(() => {
    if (data.length <= 0) handleGitData()
    if (data.length > 0) setLoaded(true)
  })

  return (
    <div >
      <CVPage background={background} setPage={setPage} >
        {data.map((item, i) => {
          return (
            <div key={'p' + i} className="section">
              <a href={item.deploymentURL ? item.deploymentURL :item.url} className="genericButton">
                {item.name}
              </a>
              <div className="description">
                {'Main language: ' + item.language}
              </div>
              <div className="description">
                {'Last updated: ' +
                  new Date(item.lastUpdated).toLocaleDateString()}
              </div>
              <div className="jobContent">{item.description}</div>
            </div>
          )
        })}
      </CVPage>
    </div>
  )
}

export default Projects
